import React from 'react';
import PageRoot from '../components/common-components/templates/page-root-v2';
import DetailContent from '../components/content-components-v2/detail';

const ContentDetailPage = data => {
  const isLogoAlwaysVisible = true;
  // eslint-disable-next-line react/destructuring-assignment
  const cmsData = data.pageContext;
  if (!('id' in cmsData)) return <PageRoot>NOT FOUND</PageRoot>;

  const getEyeCatchImgUrl = nodeJson => {
    if (!nodeJson.featured_media) {
      return undefined;
    }
    // CAUTION: this code gets full size picture srcUrl
    return nodeJson.featured_media.localFile.childImageSharp.sizes.src;
  };

  const author = nodeJson => {
    if ('id' in nodeJson.author) return nodeJson.author;
    return nodeJson.author[0];
  };

  const wpId = cmsData.wordpress_id;
  const { title, date } = cmsData;
  const authorname = author(cmsData).name;
  const content = unescape(
    cmsData.content
      .replace(/<[^>]+>/g, ' ')
      .replace(/ +/g, ' ')
      .replace(/[;\n]/g, '')
      .replace(/&#x/g, '%u'),
  ).substr(0, 120);
  const imageUrl = getEyeCatchImgUrl(cmsData);
  const ogpInfo = {
    pageUrl: `/contents/${wpId}/`,
    pageTitle: `${title} | 読み物 RegnioLab`,
    imageUrl,
    pageDescription: `${title} ${authorname} ${date} ${content}...`,
  };

  return (
    <PageRoot
      heroYOffset={100}
      isLogoAlwaysVisible={isLogoAlwaysVisible}
      pageUrl={ogpInfo.pageUrl}
      pageTitle={ogpInfo.pageTitle}
      pageDescription={ogpInfo.pageDescription}
      ogpImageName={ogpInfo.iamgeName}
      ogpImageUrl={ogpInfo.imageUrl}
    >
      <DetailContent
        wpId={wpId}
        cmsData={cmsData}
        eyeCatchImgUrl={ogpInfo.imageUrl}
      />
    </PageRoot>
  );
};

export default ContentDetailPage;
