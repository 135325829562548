import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import FlaskLogoSrc from '../../../images-v2/svg/icon_flask.svg';
import MemberFluidImages from '../../../images/gatsby-image/member-page/fluid-images';
import membersProfile from '../../../constants-v2/page-contents/members-profile';
import '../../../styles/foundation-v2.css';
import colorPaletteV2 from '../../../styles/color-palette-v2';
import ContentsCms from '../../../constants-v2/page-contents/contents-cms';

const Root = styled.div`
  display: grid;
  gap: 2vw;
  @media (max-width: 959px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    column-gap: 2vw;
    row-gap: 42px;
  }
  @media (min-width: 960px) {
    grid-template-columns: repeat(3, minmax(288px, 1fr));
    column-gap: 2vw;
    row-gap: 40px;
  }
`;

const ListItemBox = styled(Link)`
  width: 100%;
`;

const ListItemBoxPicNone = styled.div`
  background: center;
  background-image: url('${FlaskLogoSrc}');
  background-color: #ffffff;
  background-size: 32%;
  background-repeat: repeat-x;
  &:hover {
    opacity: 0.7;
  }
  transition: 0.4s all;
  @media (max-width: 1023px) {
    width: 100%;
    height: 234px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 200px;
  }
`;

const ListItemBoxPic = styled.div`
  background: no-repeat center;
  border: 0;
  background-size: cover;
  &:hover {
    opacity: 0.7;
  }
  transition: 0.4s all;

  @media (max-width: 1023px) {
    width: 100%;
    height: 234px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 200px;
  }
`;

const ListItemCaption = styled.div`
  font-size: 18px;
  margin-top: 10px;
  line-height: 27px;
  font-weight: 500;
  overflow: hidden;
  word-break: break-all;
`;

const ListItemUpperColumn = styled.div``;

const ListItemTags = styled.div`
  font-size: 16px;
  margin-top: 7px;
  line-height: 28.8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  font-weight: 400;
`;

const ListItemTagsItem = styled.span`
  margin-right: 4px;
  color: ${colorPaletteV2.blue};
  /* cursor: pointer;
  &:hover {
    text-decoration: underline;
  } */
`;

const ListItemDateAutor = styled.div`
  display: flex;
  margin-top: 6px;
`;
const ListItemAuthor = styled.div`
  display: flex;
`;

const ListItemAuthorAvator = styled(Img)`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const ListItemAuthorNameBlock = styled.div``;

const ListItemAuthorName = styled.div`
  margin-left: 8px;
  font-size: 16px;
  line-height: 28.8px;
`;

const ListItemAuthorDate = styled.div`
  margin-right: 16px;
  font-size: 16px;
  line-height: 28.8px;
`;

const ContentList = () => {
  const [cmsData] = useState(ContentsCms());
  const url = useLocation();
  const onContentsPage = url.pathname;
  let articles;
  if (onContentsPage === '/contents' || onContentsPage === '/contents/') {
    articles = cmsData.allWordpressPost.edges;
  } else {
    articles = cmsData.allWordpressPost.edges.slice(0, 6);
  }

  const generateTagList = nodeJson => {
    if (!nodeJson.tags || nodeJson.tags.length === 0) {
      return '';
    }

    return nodeJson.tags.map(tag => {
      return (
        <ListItemTagsItem key={tag.wordpress_id}>#{tag.name}</ListItemTagsItem>
      );
    });
  };

  const getEyeCatchImgUrl = nodeJson => {
    if (!nodeJson.featured_media) {
      return undefined;
    }
    return nodeJson.featured_media.localFile.childImageSharp.fluid.src;
  };

  const generateEyeCatch = nodeJson => {
    const thumbnail = getEyeCatchImgUrl(nodeJson);
    if (thumbnail === undefined) {
      return <ListItemBoxPicNone />;
    }
    return <ListItemBoxPic style={{ backgroundImage: `url(${thumbnail})` }} />;
  };

  const author = nodeJson => {
    const wpAuthor =
      'id' in nodeJson.author ? nodeJson.author : nodeJson.author[0];
    const wpAuthorNo = Number(wpAuthor.description) || 2; // default Author is Mayumi

    return membersProfile.find(item => item.wpAuthorNo === wpAuthorNo);
  };

  return (
    <Root>
      {articles.map(({ node }) => (
        <ListItemBox
          key={node.wordpress_id}
          to={`/contents/${node.wordpress_id}/`}
        >
          <ListItemUpperColumn>
            {node.featured_media !== null ? (
              generateEyeCatch(node)
            ) : (
              <ListItemBoxPicNone />
            )}
            <ListItemCaption>{node.title}</ListItemCaption>
          </ListItemUpperColumn>
          <ListItemTags>{generateTagList(node)}</ListItemTags>
          <ListItemDateAutor>
            <ListItemAuthorDate>{node.date}</ListItemAuthorDate>
            <ListItemAuthor>
              <ListItemAuthorAvator
                fluid={MemberFluidImages(author(node).image)}
              />
              <ListItemAuthorNameBlock>
                <ListItemAuthorName>
                  {author(node).name.kanji}
                </ListItemAuthorName>
              </ListItemAuthorNameBlock>
            </ListItemAuthor>
          </ListItemDateAutor>
        </ListItemBox>
      ))}
    </Root>
  );
};

export default ContentList;
