import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import metaData from '../../../constants-v2/meta-data';
import colorPaletteV2 from '../../../styles/color-palette-v2';
import FlaskLogoSrc from '../../../images-v2/svg/icon_flask_yomimono_detail.svg';
import FlaskLogoSrcEyeCatch from '../../../images-v2/svg/icon_flask.svg';
import MemberFluidImages from '../../../images/gatsby-image/member-page/fluid-images';
import membersProfile from '../../../constants-v2/page-contents/members-profile';
import ShareButton, {
  ShareButtonLabel,
} from '../../common-components/buttons/share-button-v2';
import pagesV2 from '../../../constants-v2/pages-v2';
import ContentList from '../search/content_list';
import '../../../styles/foundation-v2.css';

const wpContentSideMarginPc = 8;
const wpContentSideMarginSp = 2.4;

const captionWidthPc = '608px';
const captionWidthSp = `calc(100% - ${wpContentSideMarginSp * 2}px)`;

const contentFontP = {
  sp: {
    font: '18px',
    line: '36px',
  },
  pc: {
    font: '18px',
    line: '36px',
  },
};

const contentFontH = {
  sp: {
    h1: {
      font: '24px',
      line: '36px',
    },
    h2: {
      font: '24px',
      line: '36px',
    },
    h3: {
      font: '21px',
      line: '31.5px',
    },
    h4: {
      font: '17px',
      line: '27px',
    },
  },
  pc: {
    h1: {
      font: '24px',
      line: '34.75px',
    },
    h2: {
      font: '24px',
      line: '34.75px',
    },
    h3: {
      font: '18px',
      line: '24.06px',
    },
    h4: {
      font: '16px',
      line: '23.17px',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

const BodyWrapper = styled.div`
  z-index: 1;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const CaptionRightAlign = styled(Link)`
  display: flex;
  align-items: center;
  width: 110px;
  margin: 0 0 24px auto;
  @media (max-width: 1023px) {
    margin-bottom: 18px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const TopAttention = styled.h2`
  font-weight: 400;
  color: ${colorPaletteV2.blue};
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;
  }
`;

const RegnioFrasco = styled.img``;

const WpContentWrapper = styled.div`
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
  }
`;

const EyeCatchPicNone = styled.div`
  background: #fff center;
  background-image: url('${FlaskLogoSrcEyeCatch}');
  background-size: 20%;
  background-repeat: repeat-x;

  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320px;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28px;
  }
  @media (max-width: 640px) {
    height: 210px;
  }
`;

const EyeCatchPic = styled.div`
  border: 0;
  background-size: cover;
  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320px;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28px;
  }
  @media (max-width: 640px) {
    height: 210px;
  }
`;

const WpTitle = styled.h1`
  font-size: 24px;
  line-height: 34.75px;
  font-weight: 700;
  @media (max-width: 1023px) {
    margin-bottom: 12px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`;

const WpDate = styled.div`
  vertical-align: middle;
  @media (max-width: 1023px) {
  }
  @media (min-width: 1024px) {
    display: inline-block;
  }
`;

const WpTags = styled.div`
  @media (max-width: 1023px) {
    display: block;
    line-height: 28.8px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 18px;
  }
`;

const WpTagsItem = styled.span`
  font-size: 16px;
  color: ${colorPaletteV2.blue};
  display: inline-block;
  margin-right: 5px;
`;

const WpDateAndAuthor = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 28.8px;
`;
const WpAuthor = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    margin-left: 24px;
    width: auto;
    margin-bottom: 32px;
  }
  @media (min-width: 1024px) {
    margin-left: 24px;
    margin-bottom: 40px;
  }
`;

const WpAuthorAvator = styled(Img)`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const WpAuthorName = styled.p`
  display: inline-block;
  margin-left: 8px;
  text-align: right;
  font-weight: 400;
`;

const ContentListOuter = styled.div`
  margin: 104px 0 100px;
`;

const ContentListInner = styled.div`
  margin-top: 50px;
`;

const SectionTitleFlex = styled.div`
  display: flex;
  align-items: center;
`;

const SectitonTitleright = styled.div`
  margin-left: 16px;
`;

const ButtonL = styled(Link)`
  margin: 42px auto 0;
`;
const ButtonLStr = styled.span`
  line-height: 1;
`;

const WpDescriptionWrapper = styled.div`
  text-align: left;
  & * {
    font-weight: 400;
    word-break: break-all;
    @media (max-width: 1023px) {
      font-size: ${contentFontP.sp.font};
      line-height: ${contentFontP.sp.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontP.pc.font};
      line-height: ${contentFontP.pc.line};
    }
  }
  & > * {
    @media (max-width: 1023px) {
      margin-left: ${wpContentSideMarginSp}px;
      margin-right: ${wpContentSideMarginSp}px;
      margin-bottom: 24px;
    }
    @media (min-width: 1024px) {
      margin-left: ${wpContentSideMarginPc}px;
      margin-right: ${wpContentSideMarginPc}px;
      margin-bottom: 24px;
    }
  }
  & > ol {
    margin: auto;
    padding-left: 3px;

    @media (max-width: 1023px) {
      margin-bottom: 24px;
    }
    @media (min-width: 1024px) {
      margin-bottom: 28px;
    }
  }
  & > ol > li {
    margin-bottom: 16px;
    @media (max-width: 1023px) {
      margin-left: ${wpContentSideMarginSp}px;
      margin-right: ${wpContentSideMarginSp}px;
    }
    @media (min-width: 1024px) {
      margin-left: ${wpContentSideMarginPc}px;
      margin-right: ${wpContentSideMarginPc}px;
    }
  }
  & a {
    color: ${colorPaletteV2.blue};
    &:link,
    &:visited,
    &:hover {
      color: ${colorPaletteV2.blue};
    }
  }
  & table {
    width: 100%;
    padding: 0;
  }
  & table td {
    border: 1px solid ${colorPaletteV2.black};
    padding: 1.5px;
  }
  & figure {
    text-align: center;
  }
  & figcaption {
    @media (max-width: 1023px) {
      text-align: left;
    }
    @media (min-width: 1024px) {
      text-align: right;
      margin-top: 8px;
    }
  }
  & figure.wp-block-image {
    max-width: 100%;
    & img {
      max-width: 100%;
      height: auto !important;
    }
    &
      .gatsby-image-wrapper
      > div[aria-hidden='true']
      > img[aria-hidden='true'] {
      display: none;
    }
    & .gatsby-image-wrapper {
      width: auto !important;
      min-width: 60%;
    }
  }
  & h1,
  & h2,
  & h3,
  & h4 {
    font-weight: 500;
    @media (max-width: 1023px) {
      padding-top: 16px;
    }
    @media (min-width: 1024px) {
      padding-top: 20px;
    }
  }
  & h1 {
    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h1.font};
      line-height: ${contentFontH.sp.h1.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h1.font};
      line-height: ${contentFontH.pc.h1.line};
    }
  }
  & h2 {
    padding: 2px 8px;
    border-left: solid 6px ${colorPaletteV2.blue};
    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h2.font};
      line-height: ${contentFontH.sp.h2.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h2.font};
      line-height: ${contentFontH.pc.h2.line};
    }
  }
  & h3 {
    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h3.font};
      line-height: ${contentFontH.sp.h3.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h3.font};
      line-height: ${contentFontH.pc.h3.line};
    }
  }
  & h4 {
    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h4.font};
      line-height: ${contentFontH.sp.h4.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h4.font};
      line-height: ${contentFontH.pc.h4.line};
    }
  }
`;

const DetailContent = ({ wpId, cmsData, eyeCatchImgUrl }) => {
  // console.log(cmsData.content.match(/<h(2|3)>.*?<\/h(2|3)>/g));
  // const contentIndexArr = cmsData.content.match(/<h(2|3)>.*?<\/h(2|3)>/g);
  // const renderIndex = () => {
  //   return <div>Hello</div>;
  // };
  // ↑上の4行・目次については検討中。次回リリース時に考える

  const applyLinkPolicy = html => {
    return html.replace(/<a /g, '$&target="_blank" rel="noopener noreferrer" ');
  };

  const createMarkup = () => {
    let innerHTML = '';
    if (cmsData !== {} && cmsData.content) {
      innerHTML = applyLinkPolicy(cmsData.content);
    }

    return {
      __html: innerHTML,
    };
  };

  const generateTagList = nodeJson => {
    if (!nodeJson.tags || nodeJson.tags.length === 0) {
      return '';
    }

    return nodeJson.tags.map(tag => {
      return <WpTagsItem key={tag.wordpress_id}>#{tag.name}</WpTagsItem>;
    });
  };

  const generateEyeCatch = () => {
    if (!eyeCatchImgUrl) {
      return <EyeCatchPicNone />;
    }
    return (
      <EyeCatchPic style={{ backgroundImage: `url(${eyeCatchImgUrl})` }} />
    );
  };

  const author = nodeJson => {
    const wpAuthor =
      'id' in nodeJson.author ? nodeJson.author : nodeJson.author[0];
    const wpAuthorNo = Number(wpAuthor.description) || 2; // default Author is Mayumi

    return membersProfile.find(item => item.wpAuthorNo === wpAuthorNo);
  };

  const onClickShare = () => {
    navigator.clipboard.writeText(`${metaData.domain}/contents/${wpId}`);
  };

  return (
    <Root className="wrapper-v2">
      <BodyWrapper>
        <ShareButton
          alt="SHARE"
          onClick={onClickShare}
          toastTxt="記事のURLをコピーしました"
        >
          <ShareButtonLabel>SHARE</ShareButtonLabel>
        </ShareButton>
        <CaptionRightAlign to={pagesV2.contents.path}>
          <RegnioFrasco src={FlaskLogoSrc} alt="" />
          <TopAttention>読み物一覧</TopAttention>
        </CaptionRightAlign>
        <WpContentWrapper>
          {generateEyeCatch()}
          <BodyWrapper>
            <WpTitle>{cmsData.title}</WpTitle>
            <WpTags>{generateTagList(cmsData)}</WpTags>
            <WpDateAndAuthor>
              <WpDate>{cmsData.date}</WpDate>
              <WpAuthor>
                <WpAuthorAvator
                  fluid={MemberFluidImages(author(cmsData).image)}
                />
                <WpAuthorName>{author(cmsData).name.kanji}</WpAuthorName>
              </WpAuthor>
            </WpDateAndAuthor>
            {/* {contentIndexArr && renderIndex()} */}
            {/* ↑目次については次回リリース時に */}
            <WpDescriptionWrapper dangerouslySetInnerHTML={createMarkup()} />
          </BodyWrapper>
        </WpContentWrapper>
      </BodyWrapper>
      <ContentListOuter>
        <SectionTitleFlex>
          <h2 className="section_title_left">読み物</h2>
          <SectitonTitleright>
            <SectionTitleFlex>
              <img className="FlaskLogo" src={FlaskLogoSrcEyeCatch} alt="" />
              <p className="grad_txt section_title_right">Regnio Lab</p>
            </SectionTitleFlex>
          </SectitonTitleright>
        </SectionTitleFlex>
        <ContentListInner>
          <ContentList />
          <ButtonL
            className="grad1_border_button sizeL"
            to={pagesV2.contents.path}
          >
            <ButtonLStr className="grad1_border_button_inside">
              読み物一覧へ
            </ButtonLStr>
          </ButtonL>
        </ContentListInner>
      </ContentListOuter>
    </Root>
  );
};

export default DetailContent;
