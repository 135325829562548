import { useStaticQuery, graphql } from 'gatsby';

const MemberFluidImages = image => {
  const data = useStaticQuery(graphql`
    query {
      memberBgDesktop: file(
        relativePath: { eq: "desktop/member-hero-bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberBgMobile: file(relativePath: { eq: "mobile/member-hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberAbe: file(relativePath: { eq: "members/member_abe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberAoyama: file(relativePath: { eq: "members/member_aoyama.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberHoriuchi: file(
        relativePath: { eq: "members/member_horiuchi.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberIsozaki: file(relativePath: { eq: "members/member_isozaki.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberKataoka: file(relativePath: { eq: "members/member_kataoka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberMako: file(relativePath: { eq: "members/member_mako.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberMayumi: file(relativePath: { eq: "members/member_mayumi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberNakano: file(relativePath: { eq: "members/member_nakano.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberOkuda: file(relativePath: { eq: "members/member_okuda.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberOkusa: file(relativePath: { eq: "members/member_okusa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberSaeki: file(relativePath: { eq: "members/member_saeki.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125, maxHeight: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberShin: file(relativePath: { eq: "members/member_shin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberKanagawa: file(
        relativePath: { eq: "members/member_kanagawa.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberNarukiyo: file(
        relativePath: { eq: "members/member_narukiyo.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberKozaki: file(relativePath: { eq: "members/member_kozaki.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberHaraguchi: file(
        relativePath: { eq: "members/member_haraguchi.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberSakaguchi: file(
        relativePath: { eq: "members/member_sakaguchi.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      memberTakao: file(relativePath: { eq: "members/member_takao.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      memberTakehara: file(
        relativePath: { eq: "members/member_takehara.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1152, maxHeight: 1152) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return data[image].childImageSharp.fluid;
};

export default MemberFluidImages;
