const membersProfile = [
  {
    image: 'memberIsozaki',
    post: 'エンジニア',
    name: { kanji: '磯﨑 康子', romaji: 'Yasuko Isozaki', number: 2 },
    interest: '旅行、家族',
    goodAt: ['Rails、React'],
    policy: ['丁寧に仕上げていくこと。'],
    joy: [
      'お客様に喜んでいただけた時。',
      'うまく動かなかった問題が解決した時。',
    ],
    hobby: ['プライベートなタスクの管理方法、手帳の活用について。'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 1,
  },
  {
    image: 'memberMayumi',
    post: 'エンジニア',
    name: { kanji: '眞弓 保弘', romaji: 'Yasuhiro Mayumi', number: 31 },
    interest: '仕事の半分は気合と根性',
    goodAt: ['インフラ／ネットワーク。Webに関してはフルスタック'],
    policy: ['再現性のある理論を見つけて確実にナレッジとしておく事'],
    joy: ['システムの各要素がつながって動いた時'],
    hobby: ['愛犬（チワワ）の喜ばせ方'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 2,
  },
  {
    image: 'memberAoyama',
    post: 'エンジニア',
    name: { kanji: '青山 遼', romaji: 'Ryo Aoyama', number: 1 },
    interest: '笑顔で居れる場所',
    goodAt: ['理学修士と製造業での経験'],
    policy: ['人がやりたがらない仕事を積極的に取り組む'],
    joy: ['振り返ると成長を実感するとき'],
    hobby: ['またバイク乗りたいな〜'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 3,
  },
  {
    image: 'memberNakano',
    post: '代表取締役兼CTO',
    name: { kanji: '中野 雅俊', romaji: 'Masatoshi Nakano', number: 21 },
    interest: '技術、生命、自然',
    goodAt: ['ハード/インフラ/ネットワーク。Webに関してはフルスタック。'],
    policy: [
      '社員とお客さん、会社に関わる全ての人がRegnioの仕事を通じて幸せになれること。',
    ],
    joy: [
      'お客さんの現場やそこで働く人と接する時。社員が生き生きと働く姿を見る時。',
    ],
    hobby: ['料理、絵描き、ピアノ等、何かを自分の手で生み出す創作活動。'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 4,
  },
  {
    image: 'memberShin',
    post: 'デザイナー',
    name: { kanji: '進 佳那子', romaji: 'Kanako Shin', number: 12 },
    interest: 'バレエとダンス',
    goodAt: ['UIデザイン/Webデザイン/イラストレーション/教職経験アリ。'],
    policy: [
      'ユーザーの気持ちや立場に沿って考えること。',
      'どんな人でも疎外感を感じることなく、気持ちよくアクセスできるものを作りたいです。',
    ],
    joy: [
      'お客さんにヒアリングしながらメンバーと一緒にものを作っていき、それが形になってきたとき。',
      '超えるべき自分のハードルや課題を解決できたとき。',
    ],
    hobby: ['バレエとダンス。超高齢うさぎを二匹飼っています。'],
    links: {
      note: null,
      qiita: 'https://qiita.com/KanakoShin',
      github: null,
      facebook: null,
      twitter: null,
      instagram: 'https://www.instagram.com/kanako_shin_/',
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 5,
  },
  {
    image: 'memberSaeki',
    post: '取締役CFO',
    name: { kanji: '佐伯 拓磨', romaji: 'Takuma Saeki', number: 11 },
    interest: '思いやり',
    goodAt: ['資金調達、経営管理'],
    policy: ['相手への思いやりを持ち、問題解決をし、社会貢献し続ける。'],
    joy: [
      '自信をもった状態でアウトプットができ、お客さんが成果を得て、感謝されたとき。',
    ],
    hobby: ['インテリア、飲食店の開拓、サウナを起点とした旅'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 6,
  },
  {
    image: 'memberAbe',
    post: 'バックオフィススタッフ',
    name: { kanji: '安部 美穂', romaji: 'Miho Abe', number: 1 },
    interest: '書くこと、踊ること',
    goodAt: ['Money Forword、文章を書くこと'],
    policy: ['誠実でいること、本質を考えること'],
    joy: ['新しい出会いがあったとき'],
    hobby: [
      'タヒチアンダンス、君たちキウイ・パパイア・マンゴーだね、フラメンコギター',
    ],
    links: {
      note: 'https://note.com/anbumeisui',
      qiita: null,
      github: null,
      facebook: 'https://www.facebook.com/miho.abe.3760',
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 7,
  },
  {
    image: 'memberMako',
    post: 'エンジニア',
    name: { kanji: '真子 正吉', romaji: 'Shokichi Mako', number: 31 },
    interest: '白ごはん',
    goodAt: ['データ収集、確率変数の収束'],
    policy: ['社外研修後、自社の作業効率を現状の数倍にし生産性を高める。'],
    joy: [
      '学びと学びが一本の線になり、線と線が面になり、挑戦できる内容の幅が広がった時',
    ],
    hobby: ['天気予報、ビリヤード'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 8,
  },
  {
    image: 'memberOkuda',
    post: 'エンジニア',
    name: { kanji: '奥田 祐一郎', romaji: 'Yuichiro Okuda', number: 5 },
    interest: '遊旅、食旅',
    goodAt: ['流し撮り（写真）'],
    policy: ['利用する人の立場になって開発を行っていく'],
    joy: [
      'いつか役に立つ知識やスキルが身についた時とその知識やスキルを活かせた時',
    ],
    hobby: ['写真撮影する時の構図、ドローン撮影'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 9,
  },
  {
    image: 'memberKataoka',
    post: 'デザイナー',
    name: { kanji: '片岡 彩子', romaji: 'Ayako Kataoka', number: 6 },
    interest: 'バレエ',
    goodAt: [
      'UIデザインを担当しています。毎日利用されるシステムだからこそ、利用者の方の負担をできるだけ減らす体験設計を目指しています。',
    ],
    policy: [
      '特に、業務で毎日使うツールの出来は、皆様の生活の心地よさを大きく左右するのではないでしょうか。',
      '心地よく・気持ち良く毎日を過ごせる人が増えるようなツールの設計を心がけたいと思います。',
    ],
    joy: [
      '一緒にああじゃない、こうじゃない、と職域を超えて話し合いながら、現時点での実現可能な最適解へと落とし込んだ時。',
    ],
    hobby: ['バレエとダンスにハマっています。ストレッチも気持ちがいいです'],
    links: {
      note: null,
      qiita: 'https://qiita.com/AyakoKataoka',
      github: null,
      facebook: 'https://www.facebook.com/ayako.kataoka.10',
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 12,
  },
  {
    image: 'memberKanagawa',
    post: 'エンジニア',
    name: { kanji: '金川 理沙', romaji: 'Risa Kanagawa', number: 6 },
    interest: 'いろんな場所に住んで学ぶ',
    goodAt: ['フロントエンド'],
    policy: ['人だけじゃなくていろんな事に適所適材を考えること'],
    joy: ['作ったものが思い通りに動いたとき'],
    hobby: ['言語学、衣食住全般好きです。よく引っ越ししてます。'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 13,
  },
  {
    image: 'memberNarukiyo',
    post: '事業開発',
    name: { kanji: '成清 花菜', romaji: 'Kana Narukiyo', number: 21 },
    interest: '何でも全力でチャレンジ',
    goodAt: [
      '顧客折衝/事業構想/市場調査/顧客ヒアリング/ファシリテーター/エンジニア経験あり/経営修士(MBA)を取得するべくQBSに在学中です。',
    ],
    policy: [
      '本質的な目的は何かを考えることを大切にしています。お客様の中長期的な事業変革をお手伝いします！',
    ],
    joy: [
      '「まさにこういうのが欲しかったんだよね」とお客様が言ってくださったとき。',
    ],
    hobby: [
      '誰かと協力して１つのものを作り上げることが好きです。ゴスペルを始めます！',
    ],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: 'https://www.facebook.com/profile.php?id=100005383776585',
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 14,
  },
  {
    image: 'memberKozaki',
    post: 'エンジニア',
    name: { kanji: '小﨑　祐太郎', romaji: 'Yutaro Kozaki', number: 31 },
    interest: '健康、自然',
    goodAt: ['種々のデータ分析', 'エクセルを使用した簡単な業務効率化'],
    policy: [
      '分からなくても挑戦してみること。',
      'いつかIT技術を使って人の健康に貢献してみたいです。',
    ],
    joy: ['トライ＆エラーから脱出した瞬間'],
    hobby: ['夏の怖い話'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 15,
  },
  {
    image: 'memberHaraguchi',
    post: 'エンジニア',
    name: { kanji: '原口　真一', romaji: 'Shinichi Haraguchi', number: 31 },
    interest: '旅行、人間観察',
    goodAt: ['人と話すこと'],
    policy: ['on・offのメリハリ、折れない気持ち'],
    joy: ['「やるやん！」と言われた時'],
    hobby: ['ボーリング、サッカー観戦'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    invisible: true,
    wpAuthorNo: 16,
  },
  {
    image: 'memberSakaguchi',
    post: 'エンジニア',
    name: { kanji: '坂口　至良', romaji: 'Yukiyoshi Sakaguchi', number: 6 },
    interest: '食、酒',
    goodAt: ['製造業での生産管理業務経験'],
    policy: [
      '困難なことに挑戦し成長すること。自分のやりたいことが実現できる技術力を身につけたい。',
    ],
    joy: ['問題が解決した時。'],
    hobby: [
      'コーヒー豆を自分で挽いて飲むこと。最近福岡市に引っ越してきたので市内散策すること。',
    ],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 17,
  },
  {
    image: 'memberTakao',
    post: 'エンジニア',
    name: { kanji: '高尾　舞', romaji: 'Mai Takao', number: 20 },
    interest: '旅行、ダンス',
    goodAt: ['製造業でのシステム開発経験'],
    policy: [
      'ITの得意・不得意に関係なく、実際に使ってもらえるものを作りたいです',
    ],
    joy: [
      'ユーザに喜んでもらえた時　新しいツールなど活用方法を考えながら検証している時',
    ],
    hobby: ['野球観戦　友だちとワチャワチャしながらダンスする時間が大好きです'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 18,
  },
  {
    image: 'memberTakehara',
    post: 'エンジニア',
    name: { kanji: '武原　将大', romaji: 'Shodai Takehara', number: 20 },
    interest: '家族',
    goodAt: ['モノづくりの現場で培った徹底した現場目線'],
    policy: ['難しいことに果敢にチャレンジし、一つ一つ解決していく'],
    joy: ['実現したいことが実装できたとき'],
    hobby: ['山登りやキャンプ。キャンプ道具にはこだわっていきたい'],
    links: {
      note: null,
      qiita: null,
      github: null,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
    },
    wpAuthorNo: 19,
  },
];

export default membersProfile;
