import { useStaticQuery, graphql } from 'gatsby';

const ContentsCms = () => {
  const cmdData = useStaticQuery(graphql`
    query {
      allWordpressPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            wordpress_id
            date(formatString: "YYYY/MM/DD")
            modified(formatString: "YYYY/MM/DD")
            title
            tags {
              name
              id
              wordpress_id
            }
            author {
              id
              wordpress_id
              name
              description
            }
            featured_media {
              wordpress_id
              title
              id
              source_url
              localFile {
                id
                childImageSharp {
                  sizes {
                    src
                  }
                  fluid(toFormat: JPG, jpegProgressive: true, jpegQuality: 50) {
                    src
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
  `);

  return cmdData;
};

export default ContentsCms;
