import { Slide } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import ShareIcon from '../../../images-v2/svg/ios_share-24px.svg';
import colorPaletteV2 from '../../../styles/color-palette-v2';

const Root = styled.button`
  position: fixed;
  display: flex;
  padding: 2px;
  width: 56px;
  height: 56px;
  right: 24px;
  bottom: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colorPaletteV2.blue};
  text-decoration: none;
  border: 0;
  border-radius: 50%;
  transition: 0.4s all;
  background: ${colorPaletteV2.grad1};
  cursor: pointer;
  z-index: 5;
`;

const ShareIconVector = styled.img`
  width: 24px;
  margin-top: 6px;
`;

const ShareIconButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 50%;
  width: 52px;
  height: 53px;
`;

const ToastWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 24px;
  width: 100%;
  border: 0;
  text-align: center;
`;

const Toast = styled.div`
  display: inline-block;
  background-color: ${colorPaletteV2.blue};
  color: ${colorPaletteV2.white};
  padding: 18px 32px 18px 32px;
  font-size: 16px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1), 0px 2px 16px rgba(0, 0, 0, 0.25);
  margin-left: 15px;
  margin-right: 15px;
`;

const ShareButton = ({ alt, children, onClick, toastTxt }) => {
  const [open, setOpen] = useState(false);
  const onClickButton = () => {
    if (onClick === undefined || toastTxt === undefined) return;
    onClick();

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  return (
    <Root onClick={onClickButton}>
      <ShareIconButtonInner>
        <ShareIconVector src={ShareIcon} alt={alt} />
        {children}
      </ShareIconButtonInner>
      <Slide in={open} direction="up">
        <ToastWrapper>
          <Toast>{toastTxt}</Toast>
        </ToastWrapper>
      </Slide>
    </Root>
  );
};

const Label = styled.p`
  display: block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: ${colorPaletteV2.blue};
`;

export const ShareButtonLabel = ({ children }) => {
  return <Label>{children}</Label>;
};

export default ShareButton;
